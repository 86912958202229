/**
 * 日志打印
 */

 const colors = {
	primary: "color:#409EFF",
	success: "color:#67C23A",
	warning: "color:#E6A23C",
	danger: "color:#F56C6C",
	info: "color:#909399",
	purple: "color:#CC00FF",
	orange: "color:#FF9966",
	black: "color:#000000",
	gray: "color:#CCCCCC",
  };
  const fontStyle =
	"font-family:PingFang SC-Medium, PingFang SC;line-height:1.8;";
  const titleStyle = fontStyle + "font-weight:bold;";
  
  const log = function(...rest) {
	const { print, isDev } = this.option;
	if (!print.includes("log")) return;
	if (isDev) {
	  const prepend = `%c${this.option.prepend} DEBUG: `;
	  let args = getStyleString.call(this, "log", prepend, rest);
	  console.log(...args);
	}
  };
  
  const warn = function(...rest) {
	const { print, isDev } = this.option;
	if (!print.includes("warn")) return;
	if (isDev) {
	  const prepend = `%c${this.option.prepend} WARN: `;
	  let args = getStyleString.call(this, "warn", prepend, rest);
	  console.warn(...args);
	}
  };
  
  const error = function(...rest) {
	const { print, isDev } = this.option;
	if (!print.includes("error")) return;
	if (isDev) {
	  if (rest.length == 0 || !rest[0]) return;
	  const prepend = `%c${this.option.prepend} ERROR: `;
	  let args = getStyleString.call(this, "error", prepend, rest);
	  
	  console.error(...args);
	}
  };
  
  function getStyleString(type, prepend, rest) {
	let styles = [];
	let style = fontStyle;
	// 给每项加的样式
	if (type == "log") {
	  style += colors[this.option.style] || this.option.style;
	}
  
	let args = rest.map((v) => {
	  if (typeof v === "string") {
		styles.push(style);
		return "%c" + v + " ";
	  }
	  styles.push(v);
	  return "%o ";
	});
	if(!args.some(v=>this.option.filter.test(v)))return ''
	// 给前缀加的样式
	if (this.option.prepend) {
	  args.unshift(prepend);
	  styles.unshift(titleStyle);
	}
	args = [args.join(""), ...styles];
	return args;
  }
  
  // 只在开发环境打印，生产环境目前默认不打印
  export default function(option = {}) {
	// 前置参数
	option = Object.assign(
	  {
		prepend: "",  // 日志前缀
		style: "info", // 日志打印样式
		// 打印哪些提示，含有哪些即打印哪些 日志 警告 错误，默认都会打印
		print: ["log", "warn", "error"],
		filter:/.*/,  // 默认输出所有，正则匹配筛选过滤打印
		// 由于.env.development文件改写NODE_ENV为生产环境了，所以只能优先拿VUE_APP_MODE来判断环境
		isDev: // 环境，默认只在开发环境打印日志
		  process.env.VUE_APP_MODE == "dev" ||
		  process.env.NODE_ENV == "development",
	  },
	  option
	);
	const ctx = { option };
	return {
	  log: log.bind(ctx),
	  warn: warn.bind(ctx),
	  error: error.bind(ctx),
	};
  }
  