const { request } = require('./request')
import router from '../../router'
import wxAuth from '../wxAuth.js'
import Print from '@/utils/log.js'
import {deepClone} from "@/utils/comm.js"

const { log, error, warn } = Print({
	prepend: 'HTTP',
	style: 'primary',
	// 仅打印错误，分为三个级别 log 日志 warn 警告 error 错误，加入哪个打印哪个级别
	print:['error','log'],
	// filter:/dataCenter/  // 可启用正则筛选日志，会自动匹配符合筛选条件的日志打印
})
request.addRequestInterceptor(
	config => {
		if (config.loading) $api.showLoading(config.loading);
		// config.headers.HtAuthToken = $api.getStorageSync({
		// 	key: 'htAuthToken'
		// });
		config.headers.Authorization = 'Bearer ' + $api.getStorageSync({
			key: 'token'
		});
		// log(`发送请求\n\t请求地址：${config.url}\n\t请求参数：`, config.data || null)
		return config;
	}
)

request.addResponseInterceptor(
	response => {
		// 用于观测响应数据
		if(response.data.code<300) log(`接受响应\n\t响应地址：${response.config.url}  ${response.config.method.toUpperCase()}\n\t请求参数：`,typeof response.config.data == 'string' ? JSON.parse(response.config.data):response.config.data,`\n\t响应数据：`,deepClone(response.data))
		//判断全屏加载效果
		if (response.config.loading) $api.hideLoading()
		// 是否请求成功提示
		if (response.config.successMsg && response.data.code == 200) $api.showToast(response.config.successMsg)
		// 判断是否弹出message弹出框
		if (response.config.errMsg) {
			if (response.data.code !== 200 && response.data.code !== 12001) {
				$api.showToast(response.data.msg)
			}
		}
		if (response.data.code == 12001) {
			$api.removeStorageSync({
				key: "token"
			})
			// $api.removeStorageSync({
			// 	key: "HtAuthToken"
			// })
			$api.removeStorageSync({
				key: "userInfo"
			})
			$api.removeStorageSync({
				key: "ali_account"
			})
			// 未登陆状态时获取跳转url
			// wxAuth()
			if(router.currentRoute.fullPath !=='/my'){
				router.push({
					path:'/index'
				})
			}
		}
		if (response.data.code >= 300) {
			error(`请求接口异常\n\t请求接口：${response.config.url}\n\t响应消息：${response.data.msg}\n\t请求参数：`, response.config.data)
		}
		return response.data
	},
	err => {
		error(`网络错误\n\t错误名：${err.name}\n\t错误消息：`, err.message)
		return Promise.reject(err)
	}
)
