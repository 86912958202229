import moment from 'moment'
/**
 * 生成近几天的由日期构成的数组数据
 * @param {number} dayNumber - 近几天
 * @param {string} format - 日期格式 YYYY-MM-DD HH:mm
 * @param {boolean} isIncludeToday - 是否包含今天
 */
 export const makeLastDates = (dayNumber,format='MM-DD',isIncludeToday=false)=>{
    const date = new Date()
    const dates = []
    // date.setHours(23)
    // date.setMinutes(59)
    // date.setSeconds(59)
    let i = isIncludeToday ? 0 : 1;
    for(i;i<=dayNumber;i++){
            const time = new Date(+date)
            time.setDate(date.getDate()-i)
            dates.unshift(moment(time).format(format))
    }
    return dates
}

// 深度克隆
export const deepClone = (obj) => {
        if(typeof obj !== "object") { // && typeof obj !== 'function' 如果是函数也当原始类型返回
            return obj;        //原始类型直接返回
        }
        var o = Array.isArray(obj) ? [] : {};
        for(let i in obj) {
            if(obj.hasOwnProperty(i)){
                o[i] = typeof obj[i] === "object" ? deepClone(obj[i]) : obj[i];
            }
        }
        return o;
    }
