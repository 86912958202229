

function add0(m){return m<10?'0'+m:m }
// 时间戳转换
export const stampTime = function(val){
    var time = new Date(val* 1000); 
    var y = time.getFullYear();
    var m = time.getMonth()+1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
}
// date类型转年月日
export const ymdTime = function(val){
    var y = val.getFullYear();
    var m = val.getMonth()+1;
    var d = val.getDate();
    var h = val.getHours();
    var mm = val.getMinutes();
    var s = val.getSeconds();
    return y+'-'+add0(m)+'-'+add0(d);
}
// 秒转换分钟
export const sTm = function(val){
	var h = Math.floor( val/60 );
	var s = val%60;
	return add0(h)+':'+add0(s);
}

export const BtnStatue = function(val){

    document.getElementById(val).blur();
}

export const toKtoW = function ( val ){
	if( val < 1000){
		return val
	} else if( val >=1000 && val<10000){
		return ( val/1000 ).toFixed(1)+'k'; 
	} else {
		return parseInt( val/10000 ) +'w';
	}
}
export const formatter= function (type, value) {
	// 格式化选择器日期
	if (type === "year") {
	  return `${value}年`;
	} else if (type === "month") {
	  return `${value}月`;
	} else if (type === "day") {
	  return `${value}日`;
	} else if (type === "hour") {
	  return `${value}时`;
	} else if (type === "minute") {
	  return `${value}分`;
	}
	return value;
  }

// 复制内容到剪切板, 成功返回true
export function copyString(string){
	if(!string) return false
	let dom = document.createElement('input');
	dom.value = string;
	document.body.appendChild(dom);
	dom.select(); // 选择对象
	document.execCommand("Copy"); // 执行浏览器复制命令
	document.body.removeChild(dom)
	return true
}


