import axios from 'axios'
let base_URL = process.env.VUE_APP_API_URL;

// 创建请求
const instance = axios.create({
	baseURL: base_URL,  
	// baseURL: 'http://localhost:8080/api',	// 本地启用代理时可用，在vue.config.js中配置
	timeout: 60000,

	withCredentials: true,
	headers: {
		'HtAuthToken': sessionStorage.getItem('htAuthToken'),
		'Authorization': 'Bearer ' + sessionStorage.getItem('token')
	},
})

// 请求对象
export function request (options) {
	return instance.request(options);
}

['get', 'post', 'delete', 'put'].forEach(method => {
	request[method] = function (url, data, options) {
		return request(Object.assign(options || {}, {
			url: url,
			method: method, 
			data: data,
		}));
	}
})

// 挂载请求拦截器
request.addRequestInterceptor = function (fulfilled, rejected) {
	return instance.interceptors.request.use(fulfilled, rejected);
}
// 挂载
request.addResponseInterceptor = function (fulfilled, rejected) {
	return instance.interceptors.response.use(fulfilled, rejected);
}
