// 登录
export function admin_login(options){
	return $ask.request.post('/admin/admin_login',options.data,options.options)
}
//登录验证码
export function send_login_sms(options){
	return $ask.request.post('/admin/send_login_sms',options.data,options.options)
}
//获取绑定用户
export function get_dy_bind_list(options){
	return $ask.request.post('/admin/promoter/get_dy_bind_list',options.data,options.options)
}
//推广收益列表
export function extend_question(options){
	return $ask.request.post('/admin/profit/extend_question',options.data,options.options)
}
//推广明细
export function extend_question_detail(options){
	return $ask.request.post('/admin/profit/extend_question_detail',options.data,options.options)
}
//删除绑定用户 
export function del_dy_bind(options){
	return $ask.request.post('/admin/promoter/del_dy_bind',options.data,options.options)
}
//绑定支付宝
export function bind_cash_account(options){
	return $ask.request.post('/admin/cash/bind_cash_account',options.data,options.options)
}
//绑定支付宝短信
export function send_msg(options){
	return $ask.request.post('/admin/cash/send_msg',options.data,options.options)
}
//提现
export function cash_submit(options){
	return $ask.request.post('/admin/cash/cash_submit',options.data,options.options)
}
//提现用户信息
export function get_promoter_info(options){
	return $ask.request.post('/admin/promoter/get_promoter_info',options.data,options.options)
}
