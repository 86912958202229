// 获取微信授权code
export function wechat_code(options){
	return $ask.request.post('/mobile/wechat_code',options.data,options.options)
}
// 微信登录
export function wechat_login(options){
	return $ask.request.post('/mobile/wechat_login',options.data,options.options)
}
// 验证码登陆
export function mobile_login(options){
	return $ask.request.post('/mobile/mobile_login',options.data,options.options)
}
// 登录验证码
export function mobile_sms(options){
	return $ask.request.post('/mobile/sms',options.data,options.options)
}
// 微信绑定账号
export function wx_bind_account(options){
	return $ask.request.post('/mobile/account/wx_bind_account',options.data,options.options)
}
// 公众号支付登录
export function use_pay_login(options){
	return $ask.request.post('/use_pay/login',options.data,options.options)
}
// 公众号支付授权
export function use_pay_wechat_code(options){
	return $ask.request.post('/use_pay/wechat_code',options.data,options.options)
}
// 公众号支付下单
export function use_pay_create_order(options){
	return $ask.request.post('/use_pay/create_order',options.data,options.options)
}