import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import './assets/css/base.css'
import "normalize.css"
import './utils/vant'
import * as request from './utils/request'
import * as api from './common'
import * as filters from './utils/filters.js'

window.$ask = request;
window.$api = api;
window.$filters=filters;
Object.keys(filters).forEach(key=>{
    Vue.filter(key,filters[key])//插入过滤器名和对应方法 
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
