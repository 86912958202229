import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../pages/table/index.vue'),
    meta: {
        title: '首页',
        keepAlive:true
    }
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('../pages/table/my.vue'),
    meta: {
      title: '我的',
    }
  },
  {
    path: '/promoteDetail',
    name: 'promoteDetail',
    component: () => import('../pages/promoteDetail/promoteDetail.vue'),
    meta: {
      title: '推广明细'
    }
  },
  {
    path: '/topicDetail',
    name: 'topicDetail',
    component: () => import('../pages/topicDetail/topicDetail.vue'),
    meta: {
      title: '推广明细'
    }
  },
  {
    path: '/accountManag',
    name: 'accountManag',
    component: () => import('../pages/accountManag/accountManag.vue'),
    meta: {
      title: '抖音账号管理'
    }
  },
  {
    path: '/profitCenter',
    name: 'profitCenter',
    component: () => import('../pages/profitCenter/profitCenter.vue'),
    meta: {
      title: '收益详情'
    }
  },
  {
    path: '/profitCenterList',
    name: 'profitCenterList',
    component: () => import('../pages/profitCenter/profitCentreList.vue'),
    meta: {
      title: '收益详情'
    }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('../pages/withdraw/withdraw.vue'),
    meta: {
      title: '提现'
    }
  },
  {
    path: '/cashAccount',
    name: 'cashAccount',
    component: () => import('../pages/cashAccount/cashAccount.vue'),
    meta: {
      title: '提现账户'
    }
  },
  {
    path: '/resultsPage',
    name: 'resultsPage',
    component: () => import('../pages/resultsPage/resultsPage.vue'),
    meta: {
      title: '海豚告诉你'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
})

export default router
