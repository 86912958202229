// 获取首页列表
export function get_index_list(options){
	return $ask.request.post('/admin/index/get_index_list',options.data,options.options)
}
// 获取二维码
export function extend_qrcode(options){
	return $ask.request.post('/admin/promoter/extend_qrcode',options.data,options.options)
}
//公共配置接口
export function get_config_list(options){
	return $ask.request.post('/admin/index/get_config_list',options.data,options.options)
}
//获取答案接口
export function get_result_info(options){
	return $ask.request.post('/admin/index/get_result_info',options.data,options.options)
}