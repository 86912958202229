export function getStorageSync(options) {
  return localStorage.getItem(options.key)
}

export function setStorageSync(options) {
  return localStorage.setItem(options.key, options.item)
}

export function removeStorageSync(options) {
  return localStorage.removeItem(options.key)
}

export function clearStorageSync() {
  return localStorage.clear()
}
// 添加操作记录接口
export function upAddOperationLog(parentId,childrenId,item) {
  if(parentId && childrenId){
    $api.add_operation_log({
      data:{
        menu_id:parentId,
        menu_child_id:childrenId,
        remark:item,
        jump_admin:getStorageSync({key: 'jump_admin'})?getStorageSync({key: 'jump_admin'}):''
      }
    })
  }else{
    return;
  }
}